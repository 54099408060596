import React from "react";
import { Link } from "gatsby";
import PropTypes, { object, oneOfType, string } from "prop-types";

export const ServicesTeaser = ({ title, text, ctaLink, ctaText }) => {
  const style = {
    whiteSpace: "pre-line",
  };
  ServicesTeaser.propTypes = {
    title: PropTypes.string,
    text: oneOfType([object, string]),
    ctaLink: PropTypes.string,
    ctaText: PropTypes.string,
  };
  return (
    <div className="float-teaser float-teaser-right bg-monday-yellow mb-5 reveal">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-6">
            <div className="float-teaser-body p-2 py-md-4 pl-lg-5">
              <h2 className="h3">{title}</h2>
              <p style={style}>{text}</p>
              <Link to={ctaLink} className="btn">
                {ctaText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
